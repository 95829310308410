// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width-base: 60px;

// Colors
$header-background:		#ffffff;
$brand-primary:         #3B8686;
$white: 				#ffffff;
$white-tint: 			rgba(255,255,255,0.1);
$black-tint: 			rgba(0,0,0,0.3);
$dark-tint: 			rgba(33,33,33,0.8);
$dark: 					#333333;
$image-credit: 			rgba(255,255,255,0.85);
$footer-background: 	#212121;

$highlight:				#BE3455;
$body-background:		#ffffff;

$row-background: 		#f0f0f0;

$section-header:		#333333;

// Fonts
$font-heading: 'Lora', sans-serif;
$font-body: 'Assistant', serif;

$ez: ease-in-out;

$bullet: "\e901";

$spacing: 0.15rem;

:root {
	--font-serif: 'Lora', serif;
	--font-sans-serif: 'Assistant', sans-serif;
	--aspect: 1/1.3;
	--bs-dark: #222;
	--bs-hover: #BE3455;
}