

footer {
	background:$footer-background;	
	color:$white;
	font-family:var(--font-sans-serif);

	.brand {
		display:block;
		margin:2.5rem auto;
		max-width:500px;
		height:auto;
		line-height:1;
		
		svg {
			height:auto;
			width:100%;
			
			path {
				fill: $white;	
			}
		}
	}

	.footer-content {
		padding:3rem 0 3rem;
		border-bottom:1px solid #444;

		p {
			font-size:1.6rem;
			line-height:1;

			&.copyright {
				text-align:center;
				color:#888;
				font-size:0.95rem;
				margin:0;
			
				a {
					color:#888;
			
					&:hover {
						color:#aaa;
					}
				}
			}	
			
			.ital {
				font-style: italic;
			}
		}
	}

	h3 {
		font-size:1rem;
		text-transform:uppercase;
		letter-spacing:$spacing;
		position:relative;
		padding-bottom:0.25rem;
	}

	ion-icon {
		font-size:1.8rem;
		margin-right:0.6rem;
	}

	a {
		color:#fff;
		font-family: var(--font-serif);
		transition:color 0.2s ease-in-out;

		&:hover {
			text-decoration: none;
		}
	}

	.border-r {
		border-right:1px solid #444;
	}

}

.footer-links {

	.footer-nav {
		list-style:none;
		padding:0;	
	
		display:flex;
		flex-direction:row;
		gap:3rem;
		text-transform:uppercase;
		justify-content: center;
		align-items: center;
		margin:0 auto;

		.menu-item {
			font-family:var(--font-sans-serif);
			font-size: 1.125rem;
			margin:0;

			a {
				transition:color 0.2s ease-in-out;
			}
		}
	}

}



#newsletter-email {
	width:100%;
	border:none;
	padding:0.25rem 0.5rem;
	margin-bottom:0.5rem;
}


@media screen and (max-width:768px){
	footer {
		h3 {
			display:inline-block;
		}
		
		.brand {

		}
	}
}
