/* Directory styles */

.filter-bar {
	margin:2rem 0 1rem;
	
	button {
		border:none;
		background:none;
		padding:0.5rem 1rem;
		margin:0.25rem;
		background:rgba(0,0,0,0.08);
		font-size:0.8rem;
		text-transform:uppercase;
		display:inline-block;
	}
}

.main-content {
	.directory-type {
		margin:0;
	}
}
.directory-view {
	.main-content h1.page-title {
		text-transform:none;
		font-weight:400;
		font-size:2.2rem;
		margin-bottom:1rem;
	}
}
.directory-feature {
	width:100%;
	height:auto;
}
.directory-content {
	margin-bottom:2rem;
}
.directory-map {
	min-height:400px;
}
.directory-meta {
	background:$white;
	padding:1rem;
	line-height:1;
}
.directory-type {
	text-transform:uppercase;
	font-size:0.8rem;
	line-height:1;
	margin:0;
	display:inline-block;
}
.directory-hero {
	margin-bottom:1.5rem;	
}

.panel {
	margin-bottom:1.75rem;
	background:$white;
	position:relative;
	border-radius:0.25rem;
	
	.panel-hero {
		position:relative;
		overflow:hidden;
		
		img {
			width:100%;
			height:auto;
			transform:scale(1);
			transition:transform 0.6s $ez;
		}
		
		&:hover {
			img {
				transform:scale(1.02);
			}
		}
	}
	
	.panel-content {
		padding:1.5rem 0 0;
		
		.panel-title {
			font-size:1.4rem;
			letter-spacing:0;
			margin:0;
			text-transform:none;
			line-height:1.15;
			font-weight:400;
		}
		
		.panel-subtitle {
			font-size:0.8rem;
			text-transform:uppercase;
			margin:0 0 0.75rem;
		}
		
		.panel-text {
			margin:1rem 0 0 0;
		}
		
		.meta {
			text-transform:uppercase;
			font-size:0.7rem;
			margin-bottom:0.8rem;
		}
	}
	
	.readmore {
		position:absolute;
		left:1.5rem;
		bottom:0.25rem;
		font-size:0.8rem;
		padding:0.5rem 0.75rem;
	}
}

.iso-grid {
	.col-lg-6 {
		padding-left:15px;
		padding-right:15px;
	}
}

.directroy-view {
	.main-content h1.page-title {
		text-transform:none;
		letter-spacing:0;
		font-weight:400;
	}
}

.main-content h1.entry-title {
	text-transform:none;
	letter-spacing:0;
	font-weight:400;
	font-size:2.5rem;
}

.main-content .meta {
	text-transform:uppercase;
	font-size:0.7rem;
	margin-bottom:0.8rem;
}
/*********************
SOCIAL SHARE BUTTONS
*********************/

.social-buttons {
	float:right;
	margin-top:0.25rem;
	
	.share-title {
		font-family:$font-heading;
		font-size:10px;
		font-weight:100;
		text-transform:uppercase;
		letter-spacing:$spacing;
		margin:0;
		float:left;
		margin-right:15px;
		line-height:16px;
	}
	
	.button-list {
		list-style:none;
		margin:0;
		padding:0;
		float:left;
		
		li {
			float:left;
			margin:0 15px 0 0;
			a {
				display:block;
				width:16px;
				height:16px;
				background-size:cover;
				background-position:center;
				background-repeat:no-repeat;
				text-decoration:none;
				color:$dark;
				
				&.facebook {
					background-image:url('../images/icon-share-facebook.png');
				}
				&.google-plus {
					background-image:url('../images/icon-share-google.png');
				}
				&.twitter {
					background-image:url('../images/icon-share-twitter.png');
				}
			}
		}
	}

}