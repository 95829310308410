/* 

	Site Banner

*/
.banner {
	background:$header-background;
	z-index:100;
	height:8vh;
	position:relative;
	transition:height 0.6s $ez;
	
	&.tall {
		height:30vh;
		max-height:300px;
	}

	display:flex;
	flex-direction:column;
	justify-content: center;
	align-items: center;
}

.logo-container {
	height:18px;
	transition:all 0.6s $ez;
	float:left;
	
	a {
		height:100%;
		width:auto;
		display:block;
		line-height:1;
	}
	
	svg {
		height:100%;
		width:auto;
		path {
			fill: $dark;
		}
	}
}

.banner-text {
	text-align: center;
	
	h1 {
		font-size:0.9rem;
		text-transform:uppercase;
		letter-spacing:$spacing;
		margin:0 0 0.5rem;
		padding:0 0 0.3rem;
		position:relative;
		font-family: var(--font-sans-serif);
		
		&:after {
			content:" ";
			width:1.6rem;
			border-bottom:2px solid $white;
			position:absolute;
			left:0;
			bottom:0;
		}
	}
	
	h2 {
		font-family:var(--font-serif);
		font-style:italic;
		font-size:3.6rem;
		line-height:1;
		margin:0;
	}
}

/*

	Site Navigation 

*/
.menu-item.active > a {
	color:var(--bs-hover) !important;
}

#navigation {
	display:grid;
	gap:20px;
	grid-template-columns:auto 1fr;
	grid-template-areas:"logo nav";
	align-items:center;
	padding:0 3vw;
	position:relative;
	height:5rem;
	font-size:1.25rem;

	.nav-container {
		background:#fff;
		grid-area: nav;
		padding:0;

		.nav {
			justify-content: flex-end;
			align-content: center;
			gap:3rem;

			> .menu-item > a {
				font-family:var(-font-serif);
			}

			.menu-item {
				margin:0;
				padding:1.875rem 0;
				line-height:1;
				white-space:nowrap;
	
				a {
					line-height:1;
					font-weight:400;
					color:var(--bs-dark);
					font-family:var(--font-sans-serif);
					position:relative;
					transition:color 0.2s ease-in-out;
	
					&:hover, &:focus, &:active {
						text-decoration:none;
						color:var(--bs-hover);
					}

				}
	
				&:hover {
					> .sub-menu {
						opacity:1;
						transform:translateY(0);
						z-index:200;
					}
				}
	
				&.menu-regions {
					.sub-menu {
						grid-auto-flow:row;
						grid-gap:4rem;

						.menu-item {
							text-align:center;

							.sub-menu {
								display:flex;
								flex-direction:row;
								flex-wrap:wrap;
								align-items:center;
								justify-content: center;
								max-width:70vw;
								grid-gap:1rem 2rem;
							}
						}
					}
				}

				> .sub-menu {
					display:grid;
					opacity:0;
					z-index:-1;

					list-style: none;
					padding:3vw 0 5vw;
					margin:0;
					background:#fff;
		
					grid-auto-flow:column;
					grid-gap: 2rem;
					grid-template-columns: auto;
					justify-content: center;
					
					position:absolute;
					left:0;
					top:5rem;
					width:100%;
					height:auto;
	
					border-top:1px solid #ddd;
					border-bottom:1px solid #ddd;
					box-shadow: 0 10px 10px 10px rgba(228,228,228,0.1);

					transform:translateY(2rem);
					transition:all 0.2s ease-in-out;
					
	
					.menu-item-has-children {
						a {
							font-weight:600;
						}
					}
	
					> .menu-item {
						padding:0;
						position:relative;
	
						> .sub-menu {
							position:relative;
							top:0;
							display:flex;
							flex-direction:column;
							gap:1rem;
							padding:1rem 0 0 0;
							border:none;
							z-index:1;
							opacity:1;
							transform:none;
							box-shadow:none;
	
							> .menu-item {
								> a {
									font-weight:400;
								}
							}
						}

						
					}
				}			
			}			
		}

		

	}
	

	.logo-container {
		grid-area:logo;
	}

	.nav-toggle {
		grid-area:nav;
		text-align:right;
		display:none;
		font-size:2rem;
		cursor:pointer;
		z-index:10000;

		.nav-open {
			display:block;
		}
		.nav-close {
			display:none;
		}

		&.toggle {
			.nav-open {
				display:none;
			}
			.nav-close {
				display:block;
			}
		}

	}
}

/*

	Page Hero, common.

*/

.hero {
	&.tall {
		min-height:93vh;
		height:93vh;
	}
	
	&.medium {
		min-height:60vh;
		height:60vh;
	}
	
	&.short {
		min-height:20vh;
		height:20vh;
	}
}

.hero {
	position:relative;
	background-size:cover;
	background-attachment:fixed;
	background-position-x:50%;
	transition:0s linear;
	transition-property:background-position-y;
	width:100%;
	
	.hero-tint {
		position:absolute;
		left:0;
		top:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
		background:$black-tint;
	}
	
	.hero-overlay {
		display:table;
		width:100%;
		height:100%;
		position:absolute;
		
		.hero-overlay-content {
			display:table-cell;
			text-align:center;
			vertical-align:middle;
			
			color:$white;
			
			h1,h2 {
				margin:0;
				line-height:1;
			}
			
			.hero-title {
				font-family:var(--font-sans-serif);
				font-weight:600;
				text-transform:uppercase;	
				letter-spacing:0.1em;
				font-size:4.2rem;
				text-shadow:0 0 1rem rgba(0,0,0,0.4);
				width:90vw;
				margin:0 auto;
			}
			
			.hero-subtitle {
				font-family:var(--font-serif);
				font-weight:400;
				font-style:italic;	
				font-size:1.6rem;
				width:50vw;
				margin:0 auto;
				line-height:1.5;
				text-shadow:0 0 1rem rgba(0,0,0,0.4);
			}
		}
	}
	
	.info-overlay {
		position:absolute;
		right:0;
		bottom:0;
		background-color:transparent;
		padding:1rem;
		color:$white;
		transition:background-color 0.2s ease-in-out;
		
		&:hover {
			background-color:rgba(0,0,0,0.5);
		}

		.info-overlay-content {
			
			p {
				margin-bottom:0;
			}
			
			.info-overlay-caption {
				font-size:1.1rem;
				font-weight:500;
			}
			
			.info-overlay-credit {
				font-size:0.6rem;
				text-transform:uppercase;
				line-height:1;
			}
		}
	}
}

/* scroll down */
$scroll-down-size: 64px;

.scroll-down {
	position:absolute;
	bottom:0;
	left:50%;
	transform:translateX(-50%);
	width:$scroll-down-size;
	height:$scroll-down-size;
	background:transparent;
	border:none;
	cursor:pointer;
	text-align:center;
	outline:none;
	
	&:focus {
		outline:none;
	}
	
	i {
		line-height:$scroll-down-size;
		color:$white;
		font-size:18px;
		animation: scroll-indicator 0.8s infinite alternate;
		display:block;
	}
}

.mouse {
	width:23px;
	height:38px;
	border:3px solid white;
	border-radius:16px;
	position:relative;
	display:inline-block;
}

.mouse:after {
	content:" ";
	position:absolute;
	width:5px;
	height:5px;
	border-radius:5px;
	background:white;
	left:6px;
	top:5px;
	animation: mouse-scroll 0.8s infinite alternate ease-in-out;
}

@keyframes mouse-scroll {
	0%{top:5px;}
	100%{top:9px;}
}

@keyframes scroll-indicator {
	0% { transform:translateY(-8px); }
	100% { transform:translateY(8px); }
}

@media screen and (max-width:1800px) {
	#navigation {
		font-size:1rem;
	}
	#navigation .nav-container .nav {
		gap: 1.25rem;
	}

	#navigation .nav-container .nav .menu-item > .sub-menu {
		grid-gap:1.125rem;
	}
}

@media screen and (max-width:1366px) {
	#navigation .nav-container .nav {
		gap: 1.125rem;
	}
}

@media screen and (max-width:1200px) {
	#navigation .nav-toggle {
		display: flex;
		justify-content:flex-end;
	}

	#navigation {
		.nav-container {
			display:flex;
			width:100vw;
			height:100vh;
			overflow-y:scroll;
			position: fixed;
			left:100vw;
			top:0;
			transition:left 0.3s ease-in-out;
			z-index:1000;
			padding:3vw;

			&.toggle {
				left:0;
			}

			.nav {
				width:calc(100% - 4rem);
				text-align:left;
				justify-content:space-between;

				.menu-item {
					padding:0;

					> .sub-menu {
						background:none;
						opacity:1;
						display:flex;
						flex-direction:column;
						position:relative;
						top: 0;
						transition:none;
						transform:none;
						flex-direction:column;
						box-shadow:none;
						border:none;
						padding:1rem 0 0 1rem;
						gap:1rem;

						> .menu-item {
							> .sub-menu {
								padding:1rem 0 0 1rem;

								> .menu-item {
									margin:0;
								}
							}
						}
					}

					&.menu-regions {
						.sub-menu {
							grid-gap:1.5rem;
							.menu-item {
								text-align: left;
								.sub-menu {
									display:flex;
									flex-direction:column;
									flex-wrap:wrap;
									align-items:flex-start;
									justify-content: flex-start;
									max-width:unset;
									text-align:left;

								}
							}
						}
					}

				}
			}
		}
	}

	#navigation .nav-container .nav .menu-item.menu-regions .sub-menu {
		gap:1rem;
	}

	#navigation .nav-container .nav .menu-item > .sub-menu .menu-item-has-children a {
		font-weight:300;
	}
}

@media screen and (max-width:1024px){
	.banner {
		.banner-text {
			width:94vw;
			
			h2 {
				font-size:3.2rem;
			}
		}
	}

	#navigation {
		.nav-container {
			padding:2rem;

			.nav {
				flex-direction:column;
			}

		}
	}
}

@media screen and (max-width:768px){
	.banner {
		.banner-text {
			h2 {
				font-size:2.8rem;	
			}
		}
	}
}

@media screen and (max-width:736px){
	.banner.tall {
		height:8vh;
		
		.banner-text {
			display:none;
		}
	}
}

@media screen and (max-width:576px){
	.scroll-down {display:none;}

	.banner.tall {
		height:8vh;
		
		.banner-text {
			display:none;
		}
	}
	
	.logo-container {
		height:16px;
		
		a, svg {
			text-align:left;
			width:auto;
			height:100%;
		}
	}
}

@media screen and (max-width:500px){
	.logo-container {
		height:14px;
	}
}